










































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.toVoidLoding /deep/ .el-dialog__footer {
  text-align: center;
}
.toVoidLoding /deep/ .el-dialog__footer .el-button {
  width: 80px;
}
.toVoidLoding /deep/ .el-dialog__body .p1 {
  text-align: center;
  font-size: 17px;
  margin: 30px 0 10px 0;
}
.toVoidLoding /deep/ .el-dialog__body .p2 {
  text-align: center;
}
